const Menu = [
  {
    heading: 'menu',
    route: '/training-institute',
    pages: [
      {
        sectionTitle: 'Training Institute',
        route: '/association/trainning-institute',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Add Training Institute',
            route: '/training-institute/new-institute',
          },
          {
            heading: 'Training Institute List',
            route: '/training-institute/institute-search',
          },
          {
            heading: 'Training Institute Report',
            route: '/training-institute/institute-dynamic-search',
            fontIcon: 'fa-plus',
            svgIcon: 'media/icons/duotune/general/gen019.svg',
          },
        ],
      },

      // {
      //   heading: 'Add Training Institute Target',
      //   route: '/training-institute/institute-target',
      //   fontIcon: 'bi-dashboard',
      //   svgIcon: '/media/icons/duotune/general/gen026.svg',
      // },
      {
        sectionTitle: 'Add Training Target',
        route: '/association/trainning-target',
        svgIcon: 'media/icons/duotune/general/gen025.svg',
        fontIcon: 'bi-app-indicator',
        sub: [
          {
            heading: 'Training Target List',
            route: '/training-institute/institute-target',
          },
          // {
          //   heading: 'Pending Training Target',
          //   route: '/training-institute/pending-institute-target',
          // },
          
        ],
      },

      {
        sectionTitle: 'Institute Mapping',
        route: '/training-institute/mapping',
        fontIcon: 'fa-plus',
        svgIcon: 'media/icons/duotune/general/gen019.svg',
        sub: [
          {
            heading: 'Map Course',
            route: '/training-institute/mapping/course',
          },
          {
            heading: 'Map Trainer',
            route: '/training-institute/mapping/trainer',
          },
          {
            heading: 'Map Assessor',
            route: '/training-institute/mapping/assessor',
          },
        ],
      },

      
    ],
  },
];

export default Menu;
