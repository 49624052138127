
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-room-institute",
  components: { Field },

  data() {
    return {
      load: false,
      formData: "" as any,

      loading: false,
    };
  },
  async created() {
    this.emitter.on("selected-table-header-data", async (data) => {
      this.formData = data;
      // api
    });
  },
  methods: {
    async submitData(event) {
      console.log(event);
      // let formData = new FormData();
      // for (var key in this.formData) {
      //   formData.set(key, this.formData[key]);
      // }
      // console.log(this.formData);
      // this.loading = true;
      // this.emitter.emit("updated-table-header-data", formData);
      this.emitter.emit("data", "hello");

      // await ApiService.post("institute/detail/save", data)
      //   .then((response) => {
      //     DrawerComponent?.hideAll();
      //     this.loading = false;
      //     if (response.status == 200) {
      //       this.emitter.emit("room-updated", true);
      //       Swal.fire({
      //         title: "Success!",
      //         text: response.data.data,
      //         icon: "success",
      //         buttonsStyling: false,
      //         confirmButtonText: "Ok",
      //         customClass: {
      //           confirmButton: "btn btn-success",
      //         },
      //       }).then(() => {});
      //     } else {
      //       let err = "";
      //       for (const field of Object.keys(response.data.errors)) {
      //         err += response.data.errors[field][0] + "<br>";
      //       }
      //       Swal.fire({
      //         title: "Please check all the required field",
      //         html: err,
      //         icon: "error",
      //         buttonsStyling: false,
      //         confirmButtonText: "Close",
      //         customClass: {
      //           confirmButton: "btn btn-danger",
      //         },
      //       });
      //     }
      //   })
      //   .catch(({ response }) => {
      //     this.loading = false;
      //     Swal.fire({
      //       title: "Unknown error",
      //       html: response.data.error,
      //       icon: "error",
      //       buttonsStyling: false,
      //       confirmButtonText: "Close",
      //       customClass: {
      //         confirmButton: "btn btn-danger",
      //       },
      //     });
      //     console.log(response);
      //   });
    },
  },
});
